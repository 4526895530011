import React from 'react';
import SideNavigationSQC from '../SideNavigationSQC/SideNavigationSQC';
import './QualityCompliance.css';

class QualityCompliance extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/hero/hero2.png)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Quality Compliance</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Quality Compliance Start --> */}

          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <article class="blog_item">
                      <div class="blog_item_img">
                        <img class="card-img rounded-0" src="assets/img/sustainability-img.jpg" alt="" />
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d;' }}>
                            QUALITY COMPLIANCE
                          </h2>
                        </div>
                        <p>
                          Textile Dyes / Auxilliaries / Dye Intermediates

                        </p>
                      </div>

                    </article>
                  </div>
                </div>
                <SideNavigationSQC />
              </div>
            </div>
          </section>
        </main>
        {/* <!-- Quality Compliance End --> */}

      </div>
    )
  }
}

export default QualityCompliance;