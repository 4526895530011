import React from 'react';
import SideNavigationSQC from '../SideNavigationSQC/SideNavigationSQC';
import './Sustainability.css';

class Sustainability extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/Sustainability/Sustainability03.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Sustainability</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Sustainability Start --> */}

          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <article class="blog_item">
                      <div class="blog_item_img">
                        <img class="card-img rounded-0" src="assets/img/Sustainability/Sustainability02.jpg" alt="" />
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d;' }}>
                            SUSTAINABILITY
                          </h2>
                        </div>
                        <p>
                          A.K.Industries values the need to achieve societal objectives, especially those related to sustainable development, environmental sustainability, social justice, equity, and economic growth in corporate growth and profitability. We implement efforts to construct an organization that promotes long-term success through accountability and proper employee growth.
                        </p>
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d;' }}>
                            OUR ONGOING COMMITMENTS ARE:
                          </h2>
                        </div>
                        <p>
                          - The Creation of WASTE-LESS Manufacturing processes. <br /><br />
                          - Improvements in technology for wastewater treatment plants. <br /><br />
                          - Waste products are recycled.
                          </p>
                          <p>
                          Furthermore, achieving sustainability is a social task involving both national and international rules. It enables us to improve the socio-economic status of A.K.Industries's direct employees and the overall community. We're also involved in a number of programs that directly affect human health, education, and reorienting living standards, all of which are helping to enhance the level of living in Gujarat's cities and surrounding areas.
                        
                          </p>
                      </div>
                    </article>
                  </div>
                </div>
                <SideNavigationSQC />
              </div>
            </div>
          </section>
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
        {/* <!-- Sustainability End --> */}

      </div>
    )
  }
}

export default Sustainability;