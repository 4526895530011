import React from 'react';
import SideNavigationACQRG from '../SideNavigationACQRG/SideNavigationACQRG';
import './About.css';

class About extends React.Component {
  render() {
    return (
      <div>
        <main>
          {/* <!--? Hero Start --> */}
          <div className=" about-slider slider-area2 section-bg2" style={{ backgroundImage: "url(/assets/img/About/AboutUsSlider02.jpg)" }}>
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2">
                      <h2>About Us</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- About Us Start --> */}

          <section className="blog_area section-padding">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div className="blog_left_sidebar">
                    <article className="blog_item">
                      <div className="blog_item_img">
                        <img className="card-img rounded-0" src="assets/img/About/AboutBody.jpg" alt="" />
                      </div>
                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d" }}>VISION</h2>
                        </div>
                        <p>Our proactive customer service, excellence in quality and unparalleled economical products will make us the best and the biggest reactive dyestuff producer in the world. We are totally committed to upgrading social, economic and environmental conditions of mankind.</p>
                      </div>
                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d;" }}>YOUR PARTNER OF CHOICE</h2>
                        </div>
                        <p>
                          Our proactive customer service, excellence in quality, and unparalleled economic products will make us the best and the biggest reactive dyestuff producer in the world. We are committed to upgrading the social, economic, and environmental conditions of humankind.
                          From its modest beginning in 1998, The A.K.Industries today has evolved to become a plant spread over 1400 square meters of land around Ahmedabad, India. <br /><br />

                          Today the A.K.Industries manufactures a wide range of Reactive dyes sold in Europe and Far East Countries. A.K.Industries. A manufacturer of the full range of Reactive Dyes viz. Bifunctional, Vinyl Sulphone, High Exhaust, Hot Brand, and Cold Brand types of Dyes. <br /><br />

                          The Reactive dyes of A.K.Industries are sold under the brand name "Akactive". Besides manufacturing high-quality dyestuff in powder form, A.K.Industries have recently commissioned a medium-sized spray drier from the most reputed manufacturers of 'printex'. <br /><br />

                          Extensive investment in R & D and Quality Control Laboratory have enabled A.K.Industries to establish a prominent presence in the manufacture of dyestuffs. Maintaining international standards in product quality has ensured customer satisfaction reflected in the growth of business year after year for about three years now. <br /><br />

                          As an A.K.Industries has responded well to it’s responsibilities towards society. It has a sound environmental monitoring mechanism like periodic environment audits by internationally reputed agencies.
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
                <SideNavigationACQRG />
              </div>
            </div>
          </section>
          {/* <!-- About Us End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div>

    )
  }
}
export default About