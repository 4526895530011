import React from 'react';
import './Career.css';

class Career extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/Career/CareerSlider.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Careers</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Career Start --> */}

          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <article class="blog_item">
                      <div class="blog_item_img">
                        <img class="card-img rounded-0" src="assets/img/Career/CareerBody.jpg" alt="" />
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d' }}>CAREERS AT A.K.INDUSTRIES</h2>
                        </div>
                        <p>
                          Strategy can guide, and technology can innovate, but people are the ones that propel a company ahead at the end of the day. People are our strength and satisfaction at A.K.Industries, as they have won us a spot among the world's top 5 reactive dye makers. <br /><br />
                          Employees now look forward to being a part of this organization, and we are known for giving people the chance to take on challenging duties. We have a reputation that few can match in terms of exposure, working conditions, and salary. <br /><br />
                         <b> Note: Please report any such call/email to info@akindustries.in, A.K.Industries Private Limited does not seek Fee/Cash deposit for any job Offer/ Interview.</b>
                        </p>
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d' }}>
                            CURRENT OPPORTUNITIES
                          </h2>
                        </div>
                        <p>
                          There are no current opportunities.
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
        {/* <!-- Career End --> */}

      </div>
    )
  }
}

export default Career;