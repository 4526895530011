import React from 'react';
import { Link } from 'react-router-dom';
import './SideNavigationSQC.css';

class SideNavigationSQC extends React.Component {
  render() {
    return (
      <>
        <div class="col-xl-4 col-lg-4">
          <div class="blog_right_sidebar">
            <aside class="single_sidebar_widget post_category_widget">
              <h4 class="widget_title" style={{ color: '#2d2d2d' }}>In This Section</h4>
              <ul class="list cat-list active">
                <li>
                  <Link to="/sustainability" class="d-flex">
                    <p>Sustainability</p>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/quality-compliance" class="d-flex">
                    <p>Quality Compliance</p>
                  </Link>
                </li> */}
                <li>
                  <Link to="/corporate-responsibility" class="d-flex">
                    <p>Corporate Responsibility</p>
                  </Link>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </>
    )
  }
}

export default SideNavigationSQC;