import React from 'react';
import SideNavigationACQRG from '../SideNavigationACQRG/SideNavigationACQRG';
import './ChairmansMessage.css';

class ChairmansMessage extends React.Component {
  render() {
    return (
      <div>
        <main>
          {/* Hero Start */}
          <div className="slider-area2 section-bg2" style={{ backgroundImage: "url(/assets/img/ChairmansMessage/ChairmansMessageSlider02.jpg)" }}>
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2">
                      <h2>Chairman's Message</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Chairman's Message Start --> */}

          <section className="blog_area section-padding">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div className="blog_left_sidebar">
                    <article className="blog_item">
                      <img style={{ height: 330, width: 250 }} className="card-img rounded-0" src="assets/img/ChairmansMessage/Hasmukh_Patel.jpg" alt="" />

                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d;" }}>Hasmukh Patel | Chairman</h2>
                        </div>
                        <p>
                          We established A.K.Industries LTD with a challenge, faith, honesty, and devotion with a management philosophy.
                          Always we sincerely appreciate the support of employees, customers, suppliers, and all who have contributed to A.K.Industries products.
                          I promise you that we will supply the product with the best quality, the fastest lead time, and the best price.
                          Besides, we are going to do our best to achieve customer satisfaction through continuous development. Thank you for your continuous interest and support.
                        </p>
                      </div>
                    </article>
                    <article className="blog_item">
                      <img style={{ height: 250, width: 250 }} className="card-img rounded-0" src="assets/img/ChairmansMessage/CEO1.jpeg" alt="" />

                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d;" }}>Ashok Patel | Chairman</h2>
                        </div>
                        <p>
                          Our company values are focused on our employees' satisfaction and customer appreciation! Corporations have been the foundation of the societies to which they belong and one of the most potent driving factors in the growth of modern society. It is a worthwhile attempt for us to create a happier and harmonious community by building a content company. We aim to fully fulfill our social responsibility by developing a green and sustainable enterprise, setting an example of a happier workplace, and eventually realizing the shared vision of "home."
                        </p>
                      </div>
                    </article>

                  </div>
                </div>
                <SideNavigationACQRG />
              </div>
            </div>
          </section>
          {/* <!-- ChairMan's Message End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div>
    )
  }
}
export default ChairmansMessage