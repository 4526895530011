import React from 'react';
import './ReactiveDyes.css';


class ReactiveDyes extends React.Component {

  state = {
    showCommercializeProducts: false,
    showPilotBasisProducts: false
  }

  toggleCommercializedProductsHandler = () => {
    const doShowCommercializeProducts = this.state.showCommercializeProducts;
    this.setState({ showCommercializeProducts: !doShowCommercializeProducts });
  }

  togglePilotBasisProductsHandler = () => {
    const doShowPilotBasisProducts = this.state.showPilotBasisProducts;
    this.setState({ showPilotBasisProducts: !doShowPilotBasisProducts });
  }

  render() {

    let commercializeProducts = null;
    let pilotBasisProducts = null;

    if (this.state.showCommercializeProducts) {
      commercializeProducts = (
        <>
          <h1 class="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Commercialized Products </h1>
          <ul class="list cat-list" >
            <li>
              <h3>Reactive Blue 220</h3>
            </li>
            <li>
              <h3>Reactive Blue 160</h3>
            </li>
            <li>
              <h3>Reactive Blue 168</h3>
            </li>
            <li>
              <h3>Reactive Blue 5</h3>
            </li>
            <li>
              <h3>Reactive Blue 4</h3>
            </li>
            <li>
              <h3>Reactive Blue 221</h3>
            </li>
            <li>
              <h3>Reactive Blue CNG</h3>
            </li>
            <li>
              <h3>Reactive Blue 49</h3>
            </li>
            <li>
              <h3>Reactive Blue CNR</h3>
            </li>
            <li>
              <h3>Reactive Blue 222</h3>
            </li>
            <li>
              <h3>Reactive Blue 19</h3>
            </li>
            <li>
              <h3>Reactive Blue 250</h3>
            </li>
            <li>
              <h3>Reactive Blue 198</h3>
            </li>
            <li>
              <h3>Reactive Black 39</h3>
            </li>
            <li>
              <h3>Reactive Red 23</h3>
            </li>
            <li>
              <h3>Reactive Red CN2BL</h3>
            </li>
            <li>
              <h3>Reactive Red 180</h3>
            </li>
            <li>
              <h3>Reactive Red 223</h3>
            </li>
            <li>
              <h3>Reactive Red 45</h3>
            </li>
            <li>
              <h3>Reactive Red 24</h3>
            </li>
          </ul>
        </>
      )
    }

    if (this.state.showPilotBasisProducts) {
      pilotBasisProducts = (
        <>
          <h1 class="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Pilot Based Products </h1>
          <ul class="list cat-list" >
            <li>
              <h3>Reactive Blue RR</h3>
            </li>
            <li>
              <h3>Reactive Blue 109</h3>
            </li>
            <li>
              <h3>Reactive Red 21</h3>
            </li>
            <li>
              <h3>Reactive Red 222</h3>
            </li>
            <li>
              <h3>Reactive Red 58</h3>
            </li>
            <li>
              <h3>Reactive Red 22</h3>
            </li>
          </ul>
        </>
      )
    }
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/SolutionsAndDyes/ReactiveDyesSlider.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Reactive Dyes</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Reactive dyes content start --> */}

          <div class="categories-area section-padding40 gray-bg">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="section-tittle text-justify">
                    <span class="element">What we offer</span>
                    <h2>REACTIVE DYES FOR CELLULOSE</h2>
                    <p>
                      Reactive colors are five groups of reactive dyes manufactured by A.K.Industries for all cellulosic fibers, silk, and viscose rayon. Reactive colors form a chemical linkage with the cellulosic fibers in the presence of alkalies and therefore are characterized by their excellent fastness. Reactive colors are easy to use and applicable extensively in various fields such as exhaust dyeing, continuous dyeing, and printing, etc.
                    </p>
                    <p>
                      Pick your product in the table below, then request a product from the applicable range of Reactive Dyes to get specifics and a downloadable brochure for your particular requirement.
                      Information on the recommended coloration method is also given in the booklet, so you have all the knowledge you need to meet your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Reactiv dyes content end --> */}
          {/* <!-- Reactive Dye selection start --> */}
          <div class="container mt-50 mb-50">
            <div class="row">

              <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12" id="selectProcess">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <h1 style={{ color: "#2d2d2d;", textAlign: "center" }}>STATUS</h1>
                  </aside>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              {/* <h2 style={{ color: "#2d2d2d;", textAlign: "center" }}>Commercialize</h2> */}
                              <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.toggleCommercializedProductsHandler} class="btn">
                                {
                                  !this.state.showCommercializeProducts ?
                                    <>Show Commercialized Products</> :
                                    <>Hide Commercialized products</>
                                }
                              </button>
                              {commercializeProducts}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.togglePilotBasisProductsHandler} class="btn">
                                {
                                  !this.state.showPilotBasisProducts ?
                                    <>Show Pilot Based Products</> :
                                    <>Hide Pilot Based Products</>
                                }
                              </button>
                              {pilotBasisProducts}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

            </div>
          </div>

          {/* <!-- Reactive Dye selection end --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}

export default ReactiveDyes;