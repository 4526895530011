import React from 'react';
import { Link } from 'react-router-dom';
import './FooterNavigation.css';

export default class FooterNavigation extends React.Component {
  render() {
    return (
      <>

        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 nav-footer">
          <div class="single-footer-caption mb-50">
            <div class="footer-tittle">
              <h4>Company Profile</h4>
              <ul>
                <li><Link to="/about">About A.K.Industries</Link></li>
                <li>
                  <Link to="/chairmans-message">
                    Chairman's Message
                  </Link>
                </li>
                <li>
                  <Link to="/quality-control">
                    Quality Control
                  </Link></li>
                <li>
                  <Link to="/research-development">
                    Research and Development
                  </Link>
                </li>
                {/* <Link to="/group-companies">
                  Group Companies
                        </Link> */}
              </ul>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 nav-footer">
          <div class="single-footer-caption mb-50">
            <div class="footer-tittle">
              <h4>Solutions</h4>
              <ul>
                <li><Link to="/acid-dyes">Acid Dyes</Link></li>
                <li><Link to="/basic-dyes">Basic Dyes</Link></li>
                <li><Link to="/direct-dyes">Direct Dyes</Link></li>
                {/* <li><Link to="/lanasol-dyes">Lanasol Dyes</Link></li> */}
                <li><Link to="/reactive-dyes">Reactive Dyes</Link></li>
                {/* <li><Link to="/salt-free-dyes">Salt Free Dyes</Link></li> */}

                {/* <li>
                  <Link to="/textile-auxiliaries">Textile Auxiliaries</Link>
                </li>
                <li>
                  <Link to="/digital-textile-printing">
                    Digital Textile Printing
                          </Link>
                </li>
                <li>
                  <Link to="/construction-chemicals">
                    Construction Chemicals
                          </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 nav-footer">
          <div class="single-footer-caption mb-50">
            <div class="footer-tittle">
              <h4>Sales Network</h4>
              <ul>
                <li><Link to="/sales-network">Overview</Link></li>
                <li>
                  <Link to="/international-sales-network">
                    International Sales Network
                  </Link>
                </li>
                <li>
                  <Link to="/india-sales-network">
                    India Sales Network
                  </Link>
                </li>
                {/* <li><Link to="/partner-program">
                  Partner Program
                          </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

      </>
    )
  }
}
