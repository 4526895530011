import React from 'react';
import InternationalIndiaNetwork from '../InternationalIndiaNetwork/InternationalIndiaNetwork';
import SideNavigationIIP from '../SideNavigationIIP/SideNavigationIIP';
import './SalesNetwork.css';

class SalesNetwork extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/SalesNetwork/SalesNetworkSlider.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Sales Network</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Sales Network Start --> */}

          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <article class="blog_item">
                      <div class="blog_item_img">
                        <img class="card-img rounded-0" src="assets/img/SalesNetwork/SalesNetwork01.jpg" alt="" />
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d' }}>SALES NETWORK</h2>
                        </div>
                        <p>
                          However innovative we might have tried to be, our march into the 21st century of a global business would have led to nowhere without the support of an organized distribution network catering to both domestic and international clients. <br /><br />
                          Our confidence rides on the strong shoulders of more than 50 channels of distribution in the domestic market. Moreover, our products are exported to over 40 countries around the globe and are available in most of the dye-consuming centers around the world with the help of our skillful marketing associates. <br /><br />
                          Our product offering extends to various reactive and disperse dyes befitting each market segment and catering to different application processes like warm exhaust, hot exhaust, cold pad batch, continuous dyeing, and printing.
                        </p>
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d' }}>MARKETING NETWORK</h2>
                        </div>
                        <p>
                          Products are available through well appointed agency network and the company is actively filling up gaps by either having its own establishment or agency network in regions where there are no representations as of now.
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
                <SideNavigationIIP />
              </div>
            </div>
          </section>
          <InternationalIndiaNetwork />
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
        {/* <!-- Sales Network End --> */}

      </div>
    )
  }
}

export default SalesNetwork;