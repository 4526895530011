import React from 'react';
import { Link } from 'react-router-dom';
import SideBarContact from '../SideBarContact/SideBarContact';
import './SideNavigationIIP.css';

class SideNavigationIIP extends React.Component {
  render() {
    return (
      <>

        <div class="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
          <div class="blog_right_sidebar">
            <aside class="single_sidebar_widget post_category_widget">
              <h4 class="widget_title" style={{ color: '#2d2d2d' }}>In This Section</h4>
              <ul class="list cat-list active">
                <li>
                  <Link to="/sales-network" class="d-flex">
                    <p>Overview</p>
                  </Link>
                </li>
                <li>
                  <Link to="/international-sales-network" class="d-flex">
                    <p>International Sales Network</p>
                  </Link>
                </li>
                <li>
                  <Link to="/india-sales-network" class="d-flex">
                    <p>India Sales Network</p>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/partner-program" class="d-flex">
                    <p>Partner Program</p>
                  </Link>
                </li> */}
              </ul>
            </aside>

            <SideBarContact />

          </div>
        </div>

      </>
    )
  }
}

export default SideNavigationIIP;