import React from 'react';
import FooterNavigation from '../FooterNavigation/FooterNavigation';
import FooterContact from '../FooterContact/FooterContact';
import FooterCopyRight from '../FooterCopyRight/FooterCopyRight';
import './Footer.css';

export default class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer>
          {/* <!-- Footer Start--> */}
          <div class="footer-area footer-main">
            <div class="container">
              <div class="row d-flex justify-content-between">
                <FooterNavigation />
                <FooterContact />
              </div>
            </div>
          </div>
          {/* <!-- footer-bottom area --> */}
          <FooterCopyRight />
          {/* <!-- Footer End--> */}
        </footer>
      </div >
    )
  }
}