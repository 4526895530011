import React from 'react';
import './FooterContact.css';

export default class FooterContact extends React.Component {
  render() {
    return (
      <>

        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 inquiry-footer">
          <div class="single-footer-caption mb-50">
            <div class="footer-tittle">
              <h4>Contact Us</h4>
              <div class="footer-pera">
                {/* <p class="info1">
                  Address: A.K.Industries,
                  Plot No.1315 Phase-III G.I.D.C.
                  Vatva B/H Vijay Transport,
                  Ahmedabad - 382 445.
                  Gujarat, INDIA
                </p> */}
                <p class="info1">
                  Address: <br></br>
                  A.K.Industries, Plot No.1315 <br></br>
                  Phase-III G.I.D.C.<br></br>
                  Vatva B/H Vijay Transport,<br></br>
                  Ahmedabad - 382 445<br></br>
                  Gujarat, INDIA.
                </p>
              </div>
            </div>
            <div class="footer-number">
              <p>Phone: +91-79-25836372</p>
              <p>Email: info@akindustries.in</p>
            </div>
          </div>
        </div>

      </>
    )
  }
}