import React from 'react';
import { Link } from 'react-router-dom';
import './InternationalIndiaNetwork.css';

class InternationalIndiaNetwork extends React.Component {
  render() {
    return (
      <>

        <div class="gallery-area mb-50">
          <div class="container p-0 fix">
            <div class="row no-gutters">
              <div class="col-xl-6 col-lg-6 col-md-6">
                <div class="single-gallery text-center">
                  {/* <div class="gallery-img" style={{ backgroundImage: "url(assets/img/gallery/gallery2.png)" }}></div> */}
                  <div class="gallery-img" style={{ backgroundImage: "url(assets/img/gallery5.jpg)" }}></div>
                  <div class="thumb-content-box">
                    <div class="thumb-content">
                      <h3>International Sales Network</h3>
                      <p>We collect and analyze information about your general usage of the website, products, services.</p>
                      <Link to="/international-sales-network">Learn More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6">
                <div class="single-gallery text-center">
                  {/* <div class="gallery-img" style={{ backgroundImage: "url(assets/img/gallery/gallery3.png)" }}></div> */}
                  <div class="gallery-img" style={{ backgroundImage: "url(/assets/img/gallery3.jpg)" }}></div>
                  <div class="thumb-content-box">
                    <div class="thumb-content">
                      <h3>India Sales Network</h3>
                      <p>We collect and analyze information about your general usage of the website, products, services.</p>
                      <Link to="/india-sales-network">Learn More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default InternationalIndiaNetwork;