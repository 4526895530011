/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './Home.css';

import { Link } from 'react-router-dom';

class Home extends React.Component {
  render() {
    return (
      <div>
        <main>
          {/* <!--? slider Area Start--> */}
          <div class="slider-area position-relative home-slider">
            <div class="slider-active">
              {/* <!-- Single Slider --> */}
              <div class="single-slider slider-height d-flex align-items-center">
                <div class="container">
                  <div class="row">
                    <div class="col-xl-7 col-lg-9 col-md-8 col-sm-9">
                      <div class="hero__caption">
                        <h1 style={{ color: '#140c40' }} data-animation="fadeInLeft" data-delay="0.2s">
                          <span>A.K.Industries</span>
                        </h1>
                        <p style={{ color: '#140c40' }} data-animation="fadeInLeft" data-delay="0.4s">

                          The only way to lose is to give up. <br></br><br></br>
                          Despite the fact that we have high-quality dyes, we maintain the same
                          principles we did twenty years ago.
                        </p>
                        <Link
                          to="/about"
                          class="btn hero-btn"
                          data-animation="fadeInLeft"
                          data-delay="0.7s"

                        >Learn More<i class="ti-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- slider Area End--> */}
          {/* <!--? Categories Area Start --> */}
          <div class="categories-area home-body-intro gray-bg">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-10">
                  <div class="section-tittle mb-100">
                    {/* <!-- <span class="element">Features</span> --> */}
                    <h2 class="text-center">
                      "COLOUR IS THE FINISHING TOUCH TO EVERYTHNIG"
                    </h2>
                    <p class="text-justify">
                      A.K.Industries is a young, dynamic, and growing company that was formed keeping in mind the changing environment in Indian industry due to economic liberalization and globalization. We identify market needs and work to achieve a competitive advantage in the marketplace for our clients. Our strong supplier portfolio in this industry sector means that we can offer an extensive range of products under one roof. We always strive to source the most innovative high-performance specialty products and continuously add new product ranges every year. Our other associate companies are in manufacturing and the field of contract research and development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- cat End --> */}
          {/* <!-- cards start --> */}
          <div class="container">
            <div class="row">
              <div class="card-deck section-padding40">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="assets/img/QualityControl/QuialityControlSlider.jpg"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h3 class="card-title">Quality Control</h3>
                    <p class="card-text">
                      "A.K.Industries" the name is known for trust and quality of service across globe.
                      We believe in enhancing quality of people, which is ultimately shown in their performance and commitment in serving the customer at our best.
                    </p>
                    <Link to="/quality-control" class="all-btn">View More</Link>
                    {/* <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> --> */}
                  </div>
                </div>
                <div class="card">
                  <img
                    class="card-img-top"
                    src="assets/img/ResearchAndDevelopment/RnDBody.jpeg"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h3 class="card-title">Research & Development</h3>
                    <p class="card-text">
                      A.K.Industries puts a focus on research and growth. R & D provides about 4% to 5% of overall revenue. Our R & D laboratories are fully equipped with cutting-edge technology and staffed by a highly skilled team of research chemists, engineers, and technicians who represent a diverse range of items.
                    </p>
                    <Link to="/research-development" class="all-btn">View More</Link>
                    {/* <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> --> */}
                  </div>
                </div>
                <div class="card">
                  <img
                    class="card-img-top"
                    src="assets/img/CorporateResponsibility/CorporateResponsibilityBody.jpeg"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h3 class="card-title">Corporate Responsibility</h3>
                    <p class="card-text">
                      As part of the chemical industry, we at A.K.Industries have faced difficult social, economic, and environmental circumstances in recent years.
                    </p>
                    <Link to="/corporate-responsibility" class="all-btn">View More</Link>
                    {/* <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> --> */}
                  </div>
                </div>
                <div class="card">
                  <img
                    class="card-img-top"
                    src="assets/img/Sustainability/SustainabilityBody.jpeg"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h3 class="card-title">Sustainability</h3>
                    <p class="card-text">
                      A.K.Industries values the need to achieve societal objectives, especially those related to sustainable development — environmental sustainability, social justice, equity, and economic growth — in corporate growth and profitability.
                    </p>
                    <Link to="/sustainability" class="all-btn">View More</Link>
                    {/* <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- cards end --> */}
          {/* <!-- News & Events --> */}
          {/* <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                <div class="section-tittle text-center mb-15">
                  <span class="element">News and Events</span>
                </div>
              </div>
            </div>
          </div>
          <section class="home-blog-area pb-bottom">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="section-tittle mb-15">
                    <span class="element">From News</span>
                    <h2>News and Media center</h2>
                    <p>
                      We collect and analyze information about your general usage of
                      the website products services and courses.
                </p>
                    <Link to="/news" class="all-btn">View All</Link>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="home-blog-single mb-15">
                    <div class="blog-img-cap">
                      <div class="blog-img">
                        <img src="assets/img/gallery/home-blog1.png" alt="" />
                      </div>
                      <div class="blog-cap">
                        <p>22 Apr 2020</p>
                        <h3>
                          <a href="blog_details.html">
                            We might track your usage patterns to see
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="home-blog-single mb-15">
                    <div class="blog-img-cap">
                      <div class="blog-img">
                        <img src="assets/img/gallery/home-blog2.png" alt="" />
                      </div>
                      <div class="blog-cap">
                        <p>22 Apr 2020</p>
                        <h3>
                          <a href="blog_details.html"
                          >The massive stadium screens at the MCG</a
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <!-- News & Events end --> */}
        </main>


        {/* <!-- Scroll Up --> */}
        <div id="back-top">
          <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
        </div>
      </div>
    )
  };
}
export default Home