import React from 'react';
import './SideBarContact.css';

class SideBarContact extends React.Component {
  render() {
    return (
      <>

        <aside class="single_sidebar_widget post_category_widget">
          <div class="single-footer-caption mb-50">
            <div class="footer-tittle">
              <p><h2> <b>Contact Us </b> </h2> </p> 
              <div class="footer-pera">
                {/* <p class="info1">
                  Address: A.K. Industries
                  Plot No.1315 Phase-III G.I.D.C.
                  Vatva B/H Vijay Transport
                  Ahmedabad - 382 445
                  Gujarat INDIA
                </p> */}
                  <p class="info1">
                  A.K.Industries, Plot No.1315,<br></br>
                  Phase-III G.I.D.C.<br></br>
                  Vatva B/H Vijay Transport,<br></br>
                  Ahmedabad - 382 445<br></br>
                  Gujarat, INDIA.
                </p>
              </div>
            </div>
            <div class="footer-number">
              <p>Phone: +91-79-25836372</p>
              <p>Email: info@akindustries.in</p>
            </div>
          </div>
        </aside>

      </>
    )
  }
}

export default SideBarContact;