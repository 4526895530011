import React, { Component } from 'react';
import './BasicDyes.css';

class BasicDyes extends React.Component {
  state = {
    showCommercializeProducts: false
  }


  togglePilotBasisProductsHandler = () => {
    const doShowPilotBasisProducts = this.state.showPilotBasisProducts;
    this.setState({ showPilotBasisProducts: !doShowPilotBasisProducts });
  }

  render() {

    let pilotBasisProducts = null;

    if (this.state.showPilotBasisProducts) {
      pilotBasisProducts = (
        <>
          <h1 class="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Pilot Based Products </h1>
          <ul class="list cat-list" >
            <li>
              {/* <h3>Basic Blue LE</h3> */}
              <h3>Basic Blue 41</h3>
            </li>
            <li>
              {/* <h3>Basic Red GL</h3> */}
              <h3>Basic Red 29</h3>
            </li>
            <li>
              {/* <h3>Basic Blue LE-2</h3> */}
              <h3>Basic Blue 41.1</h3>
            </li>
          </ul>
        </>
      )
    }
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/SolutionsAndDyes/BasicDyesSlider.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Basic Dyes</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Reactive dyes content start --> */}

          <div class="categories-area section-padding40 gray-bg">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="section-tittle text-justify">
                    <span class="element">What we offer</span>
                    <h2>BASIC DYES</h2>
                    <p>
                      Basic dyes are known for their variety of colors, brightness and vibrancy, and compatibility with synthetic, anionic materials.
                      When it comes to coloring synthetic, cationic products like acrylics, basic dyes are the place to get there.
                      Since other types of dyes, such as disperse dyes, produce pale colors when used with these synthetic materials, basic dyes work better in this situation.
                      Basic dyes have vivid, clear, and long-lasting colors that other forms of dyes cannot produce when working with chemically compatible materials.
                    </p>
                    <p>
                      Pick your product in the table below, then request a product from the applicable range of Basic Dyes to get specifics and a downloadable brochure for your particular requirement.
                      Information on the recommended coloration method is also given in the booklet, so you have all the knowledge you need to meet your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Reactiv dyes content end --> */}
          {/* <!-- Reactive Dye selection start --> */}
          <div class="container mt-50 mb-50">
            <div class="row">

              <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12" id="selectProcess">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <h1 style={{ color: "#2d2d2d;", textAlign: "center" }}>STATUS</h1>
                  </aside>
                </div>
              </div>

              <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.togglePilotBasisProductsHandler} class="btn">
                                {
                                  !this.state.showPilotBasisProducts ?
                                    <>Show Pilot Based Products</> :
                                    <>Hide Pilot Based Products</>
                                }
                              </button>
                              {pilotBasisProducts}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

            </div>
          </div>

          {/* <!-- Reactive Dye selection end --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}

export default BasicDyes;