import React from 'react';
import InquiryData from '../InquiryData/InquiryData';
import './Inquiry.css';

const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


class Inquiry extends React.Component {



  render() {
    const mapStyle = {
      overflow: "hidden",
      paddingBottom: "45%",
      position: "relative",
      height: "0",
    }
    const iframeMapStyle = {
      left: "0",
      top: "0",
      height: "100%",
      width: "100%",
      position: "absolute"
    }
    return (

      <div>

        <main>
          {/* <!--? Hero Start --> */}
          {/* <div class="slider-area2 section-bg2" style={{ backgroundImage: "url(/assets/img/Inquiry/InquirySlider.jpg)" }}> */}
          <div class="slider-area2 section-bg2" style={{ backgroundImage: "url(/assets/img/About/AboutUsSlider02.jpg)" }}>
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2 text-left">
                      <h2>Contact Us</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!--?  Contact Area start  --> */}
          <section class="contact-section">
            <div class="container">
              <div class="row" style={{display: 'flex', justifyContent: 'center'}}>
                <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                  <div style={mapStyle}>
                    <iframe style={iframeMapStyle} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.26449794481!2d72.62836221481533!3d22.97729928497601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e88a76695bb89%3A0x15008f1b4bff90e4!2sA.K.%20Industries!5e0!3m2!1sen!2sin!4v1615540678200!5m2!1sen!2sin" width="100%" height="500" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                  </div>
                </div>

                <div class="col-lg-3 offset-lg-1">
                  <div class="media contact-info">
                    <span class="contact-info__icon"><i class="ti-home"></i></span>
                    <div class="media-body">
                      <h3>Ahmedabad, Gujarat, India</h3>
                      <p>
                        A.K.Industries
                        Plot No.1315 Phase-III G.I.D.C.
                        Vatva B/H Vijay Transport
                        - 382 445
                      </p>
                    </div>
                  </div>
                  <div class="media contact-info">
                    <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                    <div class="media-body">
                      <h3>+91-79-25836372</h3>
                    </div>
                  </div>
                  <div class="media contact-info">
                    <span class="contact-info__icon"><i class="ti-email"></i></span>
                    <div class="media-body">
                      <h3>info@akindustries.in</h3>
                      <p>Send us your query anytime!</p>
                    </div>
                  </div>
                </div>


              </div>
              <div class="row mt-40" >

                <div class="col-12">
                  <h2 style={{ textAlign: 'center' }} class="contact-title">GET IN TOUCH</h2>
                </div>
                <div class="col-lg-4"></div>
                <div class="col-lg-12" style={{display: 'flex', justifyContent: 'center'}}>
                  <InquiryData />
                </div>
                <div class="col-lg-2"></div>
              </div>
            </div>
          </section>
          {/* <!-- Contact Area End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>

      </div >
    )
  }
}

export default Inquiry;