import React from 'react';
import SideNavigationSQC from '../SideNavigationSQC/SideNavigationSQC';
import './CorporateResponsibility.css';

class CorporateResponsibility extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/CorporateResponsibility/CorporateResponsibilitySlider01.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Corporate <br /> Responsibility</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Corporate Responsibility Start --> */}

          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <article class="blog_item">
                      <div class="blog_item_img">
                        <img class="card-img rounded-0" src="assets/img/CorporateResponsibility/CorporateResponsibilityBody.jpeg" alt="" />
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d;' }}>
                            CORPORATE RESPONSIBILITY
                          </h2>
                        </div>
                        <p>
                          As part of the chemical industry, we at A.K.Industries have faced difficult social, economic, and environmental circumstances in recent years. Sustainable production and more sustainable goods that use fewer energy resources are becoming increasingly common. 
                        </p>
                        <p>
                        As a responsible business, we recognized these problems a long time ago and have improved environmental security and resource management ever since. We are also active in various programs aimed at improving the quality of life of ordinary citizens, such as education, health, and well-being. The task at hand is to make our world a better place, and we will leave no stone unturned to achieve our objectives, no matter what it takes.
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
                <SideNavigationSQC />
              </div>
            </div>
          </section>
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
        {/* <!-- Corporate Responsibility End --> */}
      </div>
    )
  }
}

export default CorporateResponsibility;