import React from 'react';
import SideNavigationIIP from '../SideNavigationIIP/SideNavigationIIP';
import './InternationalNetworkData.css';

class InternationalNetworkData extends React.Component {
  render() {
    return (
      <>

        <section class="blog_area section-padding">
          <div class="container">
            <div class="row">
              <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                <div class="blog_left_sidebar">
                  <article class="blog_item">
                    <div class="blog_item_img">
                      <img class="card-img rounded-0" src="assets/img/InternationalSalesNetwork/WorldMap.jpg" alt="" />
                    </div>
                    <div class="blog_details text-justify">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Africa</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>South Africa</td>
                                </tr>
                                <tr>
                                  <td>Egypt</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">North America</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Canada</td>
                                </tr>
                                <tr>
                                  <td>U.S.A.</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Europe</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>France</td>
                                </tr>
                                <tr>
                                  <td>Belgium</td>
                                </tr>
                                <tr>
                                  <td>Italy</td>
                                </tr>
                                <tr>
                                  <td>Switzerland</td>
                                </tr>
                                <tr>
                                  <td>Germany</td>
                                </tr>
                                <tr>
                                  <td>Spain</td>
                                </tr>
                                <tr>
                                  <td>Turkey</td>
                                </tr>
                                <tr>
                                  <td>Greece</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">South East Asia</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Thailand</td>
                                </tr>
                                <tr>
                                  <td>Malaysia</td>
                                </tr>
                                <tr>
                                  <td>Indonesia</td>
                                </tr>
                                <tr>
                                  <td>Vietnam</td>
                                </tr>
                                <tr>
                                  <td>Philippines</td>
                                </tr>
                                <tr>
                                  <td>South Korea</td>
                                </tr>
                                <tr>
                                  <td>Japan</td>
                                </tr>
                                <tr>
                                  <td>Russia</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">South America</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Brazil</td>
                                </tr>
                                <tr>
                                  <td>Mexico</td>
                                </tr>
                                <tr>
                                  <td>Chile</td>
                                </tr>
                                <tr>
                                  <td>Argentina</td>
                                </tr>
                                <tr>
                                  <td>Colombia</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">United Kingdom</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Britain</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>

                    </div>
                  </article>
                </div>
              </div>
              <SideNavigationIIP />
            </div>
          </div>
        </section>

      </>
    )
  }
}

export default InternationalNetworkData;