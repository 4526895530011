import React from 'react';
import SideNavigationACQRG from '../SideNavigationACQRG/SideNavigationACQRG';
import './QualityControl.css';

class QualityControl extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            className="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/QualityControl/QuialityControlSlider.jpg)" }}
          >
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2">
                      <h2>Quality Control</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Quality Control Start --> */}

          <section className="blog_area section-padding">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div className="blog_left_sidebar">
                    <article className="blog_item">
                      <div className="blog_item_img">
                        <img className="card-img rounded-0" src="assets/img/QualityControl/QualityControlBody.jpg" alt="" />
                      </div>
                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d;" }}>Quality Control</h2>
                        </div>
                        <p>
                          "A.K.Industries" the name is known for trust and quality of service across the globe. We believe in enhancing the quality of people, which is ultimately shown in their performance and commitment to serving the customer at our best. <br /><br />

                          To maintain this drive and to set this as a system across people in the organization, we have adopted Quality Management System. Our Systems are certified by one of the most prestigious certifying bodies. <br /><br />

                          The direction to quality for achieving excellence in the outputs is giving by the Top Management, which is displayed in our Quality Policy. <br /><br />

                          Product Quality Focus: Our Appetite for quality does not end over Systems Certification. We proudly claim to be the first trading company in India to get our product (Certificate of Suitability). <br /><br />

                          We have outsourced our laboratory facilities, which product-specific technical professionals manage, thereby conforming to the third-party certification of the product before delivery other than our factory laboratory. <br /><br />
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
                <SideNavigationACQRG />
              </div>
            </div>
          </section>
          {/* <!-- Quality Control End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}
export default QualityControl