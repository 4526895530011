import React from 'react';
import IndiaNetworkData from '../IndiaNetworkData/IndiaNetworkData';
import InternationalIndiaNetwork from '../InternationalIndiaNetwork/InternationalIndiaNetwork';
import './IndiaSalesNetwork.css';

class IndiaSalesNetwork extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/IndiaSalesNetwork/IndiaSalesNetworkSlider01.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>India Sales Network</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- India Sales Network Start --> */}
          <IndiaNetworkData />
          {/* <!-- India Sales Network End --> */}
          <InternationalIndiaNetwork />
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>


      </div>
    )
  }
}

export default IndiaSalesNetwork;