import React from 'react';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import ChairmansMessage from './components/ChairmansMessage/ChairmansMessage';
import QualityControl from './components/QualityControl/QualityControl';
import ResearchAndDevelopment from './components/ResearchAndDevelopment/ResearchAndDevelopment';
import GroupCompanies from './components/GroupCompanies/GroupCompanies';
import Solutions from './components/Solutions/Solutions';
import AcidDyes from './components/AcidDyes/AcidDyes';
import BasicDyes from './components/BasicDyes/BasicDyes';
import DirectDyes from './components/DirectDyes/DirectDyes';
import LanasolDyes from './components/LanasolDyes/LanasolDyes';
import ReactiveDyes from './components/ReactiveDyes/ReactiveDyes';
import SaltFreeDyes from './components/SaltFreeDyes/SaltFreeDyes';
import TextileAuxiliaries from './components/TextileAuxiliaries/TextileAuxiliaries';
import DigitalTextilePrinting from './components/DigitalTextilePrinting/DigitalTextilePrinting';
import ConstructionChemicals from './components/ConstructionChemicals/ConstructionChemicals';
import Sustainability from './components/Sustainability/Sustainability';
import QualityCompliance from './components/QualityCompliance/QualityCompliance';
import CorporateResponsibility from './components/CorporateResponsibility/CorporateResponsibility';
import SalesNetwork from './components/SalesNetwork/SalesNetwork';
import InternationalSalesNetwork from './components/InternationalSalesNetwork/InternationalSalesNetwork';
import IndiaSalesNetwork from './components/IndiaSalesNetwork/IndiaSalesNetwork';
import PartnerProgram from './components/PartnerProgram/PartnerProgram';
import News from './components/News/News';
import Events from './components/Events/Events';
import Inquiry from './components/Inquiry/Inquiry';
import Career from './components/Career/Career';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/chairmans-message" component={ChairmansMessage} />
          <Route path="/quality-control" component={QualityControl} />
          <Route path="/research-development" component={ResearchAndDevelopment} />
          <Route path="/group-companies" component={GroupCompanies} />
          <Route path="/solutions" component={Solutions} />
          <Route path="/acid-dyes" component={AcidDyes} />
          <Route path="/basic-dyes" component={BasicDyes} />
          <Route path="/direct-dyes" component={DirectDyes} />
         {/* <Route path="/lanasol-dyes" component={LanasolDyes} /> */}
          <Route path="/reactive-dyes" component={ReactiveDyes} />
          {/* <Route path="/salt-free-dyes" component={SaltFreeDyes} /> */}
          <Route path="/textile-auxiliaries" component={TextileAuxiliaries} />
          <Route path="/digital-textile-printing" component={DigitalTextilePrinting} />
          <Route path="/construction-chemicals" component={ConstructionChemicals} />
          <Route path="/sustainability" component={Sustainability} />
          <Route path="/quality-compliance" component={QualityCompliance} />
          <Route path="/corporate-responsibility" component={CorporateResponsibility} />
          <Route path="/sales-network" component={SalesNetwork} />
          <Route path="/international-sales-network" component={InternationalSalesNetwork} />
          <Route path="/india-sales-network" component={IndiaSalesNetwork} />
          <Route path="/partner-program" component={PartnerProgram} />
          <Route path="/news" component={News} />
          <Route path="/events" component={Events} />
          <Route path="/inquiry" component={Inquiry} />
          <Route path="/career" component={Career} />
        </Switch>
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
