import React from 'react';
import { Link } from 'react-router-dom';
import './Solutions.css';

class Solutions extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div class="slider-area2 section-bg2 solutions-slider" style={{ backgroundImage: "url(/assets/img/SolutionsAndDyes/SolutionsSlider01.jpg)" }}>
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Dyes, Auxiliaries and Garments</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}

          {/* <!--? Solutions Start --> */}
          <div class="categories-area pt-50 pb-30">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div class="section-tittle text-justify mb-100">
                    <span class="element">What we offer</span>
                    <h4>No matter in which parts of the major sectors of the textile wet processing industry you are placed, we pride ourselves that in most cases, we are able to offer a solution for your requirements. This includes several ranges of reactive dyes for the dyeing & printing, acid dyes used mainly for dyeing wool, silk, nylon and direct dyes for cotton, paper, leather.  </h4>
                  </div>
                </div>
              </div>
              <section class="home-blog-area pb-40">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="home-blog-single mb-30">
                      <div class="blog-img-cap">
                        <div class="blog-img">
                          <img src="assets/img/SolutionsAndDyes/AcidDyesSlider.jpg" alt="" />
                        </div>
                        <div class="blog-cap">
                          <p></p>
                          <h3><Link to="/acid-dyes">Acid Dyes</Link></h3>
                          <p>Making a dyeing/printing process selection, followed by a product range selection, leads you to individual dyestuff information ...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="home-blog-single mb-30">
                      <div class="blog-img-cap">
                        <div class="blog-img">
                          <img src="assets/img/SolutionsAndDyes/BasicDyesSlider.jpg" alt="" />
                        </div>
                        <div class="blog-cap">
                          <p></p>
                          <h3><Link to="/basic-dyes">Basic Dyes</Link></h3>
                          <p>Select the appropriate disperse dye based on low, medium & high energy dyes.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="home-blog-area">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="home-blog-single">
                      <div class="blog-img-cap">
                        <div class="blog-img" style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                          {/* <img src="assets/img/gallery/home-blog1.png" alt="" /> */}
                          <i class="fas fa-flask fa-5x"></i>
                        </div>
                        <div class="blog-cap">
                          <p></p>
                          <h3 class="text-center"><Link to="/basic-dyes">Basic Dyes</Link></h3>
                          <p class="text-justify">Making a dyeing/printing process selection, followed by a product range selection, leads you to individual dyestuff information ...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="home-blog-single">
                      <div class="blog-img-cap">
                        <div class="blog-img" style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                          {/* <img src="assets/img/gallery/home-blog1.png" alt="" /> */}
                          <i class="fas fa-flask fa-5x"></i>
                        </div>
                        <div class="blog-cap">
                          <p></p>
                          <h3 class="text-center"><Link to="/acid-dyes">Acid Dyes</Link></h3>
                          <p class="text-justify">Making a dyeing/printing process selection, followed by a product range selection, leads you to individual dyestuff information ...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="home-blog-single">
                      <div class="blog-img-cap">
                        <div class="blog-img" style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                          {/* <img src="assets/img/gallery/home-blog1.png" alt="" /> */}
                          <i class="fas fa-flask fa-5x"></i>
                        </div>
                        <div class="blog-cap">
                          <p></p>
                          <h3 class="text-center"><Link to="/reactive-dyes">Reactive Dyes</Link></h3>
                          <p class="text-justify">Making a dyeing/printing process selection, followed by a product range selection, leads you to individual dyestuff information ...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="home-blog-single">
                      <div class="blog-img-cap">
                        <div class="blog-img" style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                          {/* <img src="assets/img/gallery/home-blog1.png" alt="" /> */}
                          <i class="fas fa-flask fa-5x"></i>
                        </div>
                        <div class="blog-cap">
                          <p></p>
                          <h3 class="text-center"><Link to="/direct-dyes">Direct Dyes</Link></h3>
                          <p class="text-justify">Making a dyeing/printing process selection, followed by a product range selection, leads you to individual dyestuff information ...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="single-cat">
                    <div class="cat-icon">
                      <img src="assets/img/gallery/icon1.png" alt="" />
                    </div>
                    <div class="cat-cap">
                      <h5><a href="textile-auxiliaries.html">Textile Auxilixaries</a></h5>
                      <p>Suitable for various stages of Textile processing; Pretreatment, Dying & Printing and Finishing.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="single-cat">
                    <div class="cat-icon">
                      <img src="assets/img/gallery/icon2.png" alt="" />
                    </div>
                    <div class="cat-cap">
                      <h5><a href="garments.html">Denim Garments</a></h5>
                      <p>A fast growing sister company involved in garmenting of denim jeans with high level automation and finishing options.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="single-cat">
                    <div class="cat-icon">
                      <img src="assets/img/gallery/icon3.png" alt="" />
                    </div>
                    <div class="cat-cap">
                      <h5><a href="digital-textile-printing.html">Digital Printing</a></h5>
                      <p>Comprehensive range of Antelos® inks for Digital Textile Printing on natural and synthetic fibers.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="single-cat">
                    <div class="cat-icon">
                      <img src="assets/img/gallery/icon4.png" alt="" />
                    </div>
                    <div class="cat-cap">
                      <h5><a href="contruction-chemicals.html">Construction Chemicals</a></h5>
                      <p>Looking for new opportunities and using four decades of experience in manufacturing.</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </main>

        {/* Solutions End */}
        <div id="back-top">
          <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
        </div>
      </div>
    )
  }
}

export default Solutions;