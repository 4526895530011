import React, { Component } from 'react';
import './LanasolDyes.css';

class LanasolDyes extends React.Component {
  state = {
    showProducts: false,
  }

  toggleProductsHandler = () => {
    const doShowProducts = this.state.showProducts;
    this.setState({ showProducts: !doShowProducts });
  }

  render() {

    let Products = null;

    if (this.state.showProducts) {
      Products = (
        <>
          <h1 class="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Products </h1>
          <ul class="list cat-list" >
            <li>
              <h3>Lanasol Blue 3R</h3>
              <p>C.I. Number - Rea. Blue 50</p>
            </li>
            <li>
              <h3>Lanasol Blue 3G</h3>
              <p>C.I. Number - Rea. Blue 69</p>
            </li>
            <li>
              <h3>Lanasol N.Blue BDF</h3>
              <p>C.I. Number - N/A</p>
            </li>
            <li>
              <h3>Lanasol Red B</h3>
              <p>C.I. Number - Rea. Red 65</p>
            </li>
            <li>
              <h3>Lanasol Red 5B</h3>
              <p>C.I. Number - Rea. Red 66</p>
            </li>
            <li>
              <h3>Lanasol Scarlet 3G</h3>
              <p>C.I. Number - Rea. Red 136</p>
            </li>
          </ul>
        </>
      )
    }
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/SolutionsAndDyes/LanasolDyesSlider02.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Lanasol Dyes</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Reactive dyes content start --> */}

          <div class="categories-area section-padding40 gray-bg">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="section-tittle text-justify">
                    <span class="element">What we offer</span>
                    <h2>LANASOL DYES</h2>
                    <p>
                      Lanasol colors contain α-bromoacrylamide reactive groups that react with the amino groups of wool fiber to produce high wet fastness, allowing them to exceed the Woolmark standard's machine-washable wool standards. <br /><br />
                      When mixed with ALBEGAL B in somewhat acidic circumstances, Lanasol dyes offer strong leveling qualities. The trichromatic dyes Lanasol Yellow 4G, Red 6G, and Blue 3G may cover a wide shade range. These dyes are highly lightfast (even in light colors) and have excellent fiber leveling. <br /><br />
                      Yarn dyeing (package and hanks), especially in the chlorine-Hercosett process for machine-washable wool, loose stock, and top dyeing, are common uses.
                    </p>
                    <p>
                      Pick your product in the table below, then request a product from the applicable range of Lanasol Dyes to get specifics and a downloadable brochure for your particular requirement.
                      Information on the recommended coloration method is also given in the booklet, so you have all the knowledge you need to meet your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Reactiv dyes content end --> */}
          {/* <!-- Reactive Dye selection start --> */}
          <div class="container mt-50 mb-50">
            <div class="row">

              <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12" id="selectProcess">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <h1 style={{ color: "#2d2d2d;", textAlign: "center" }}>PRODUCTS</h1>
                  </aside>
                </div>
              </div>

              <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              {/* <h2 style={{ color: "#2d2d2d;", textAlign: "center" }}>Commercialize</h2> */}
                              <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.toggleProductsHandler} class="btn">
                                {!this.state.showProducts ?
                                  <>Show Products</> :
                                  <>Hide Products</>
                                }
                              </button>
                              {Products}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

            </div>
          </div>

          {/* <!-- Reactive Dye selection end --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}

export default LanasolDyes;