import React from "react";
import SideNavigationIIP from "../SideNavigationIIP/SideNavigationIIP";
import './IndiaNetworkData.css';

class IndiaNetworkData extends React.Component {
  render() {
    return (
      <>
        <section class="blog_area section-padding">
          <div class="container">
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-sm-12 col-xs-12 mb-5 mb-lg-0">
                <div class="blog_left_sidebar">
                  <article class="blog_item">
                    <div class="blog_item_img">
                      <img
                        class="card-img rounded-0"
                        src="assets/img/IndiaSalesNetwork/IndiaMapcopy.png"
                        alt=""
                      />
                    </div>
                    <div class="blog_details text-justify">
                      <div class="container">
                        <div class="row">
                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>

                                  <th scope="col">Bihar</th>
                                  <th scope="col">Delhi</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>

                                  <td>Bhagalpur</td>
                                  <td>Delhi</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>

                                  <th scope="col">Himachal Pradesh</th>
                                  <th scope="col">Jammu & Kashmir</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Baddi</td>
                                  <td>Jammu</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>

                                  <th scope="col">Karnataka</th>
                                  <th scope="col">Madhya Pradesh</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>

                                  <td>Shimoga</td>
                                  <td>Indore</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>

                                  <th scope="col">Odisha</th>
                                  <th scope="col">West Bengal</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>

                                  <td>Brahmapur</td>
                                  <td>Kolkata</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>

                                  <th scope="col">Punjab</th>
                                  <th scope="col">Andhra Pradesh</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>

                                  <td>Amritsar</td>
                                  <td>Hyderabad</td>
                                </tr>
                                <tr>

                                  <td>Ludhiana</td>
                                  <td>Visakhapatnam</td>
                                </tr>
                                <tr>

                                  <td>-</td>
                                  <td>Nagari</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>

                                  <th scope="col">Haryana</th>
                                  <th scope="col">Maharashtra</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Panipat</td>
                                  <td>Mumbai</td>
                                </tr>
                                <tr>
                                  <td>Sonipat</td>
                                  <td>Ichalkaranji</td>
                                </tr>
                                <tr>
                                  <td>Gurgaon</td>
                                  <td>Kohlapur</td>
                                </tr>
                                <tr>
                                  <td>Manesar</td>
                                  <td>Solapur</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>

                                  <th scope="col">Gujarat</th>
                                  <th scope="col">Tamil Nadu</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>

                                  <td>Ahmedabad</td>
                                  <td>Tirupur</td>
                                </tr>
                                <tr>

                                  <td>Surat</td>
                                  <td>Erode</td>
                                </tr>
                                <tr>

                                  <td>Jetpur</td>
                                  <td>Salem</td>
                                </tr>
                                <tr>

                                  <td>Rajkot</td>
                                  <td>Chennai</td>
                                </tr>
                                <tr>

                                  <td>Kalol</td>
                                  <td>Komarapalayam</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <table class="table text-center table-bordered">
                              <thead>
                                <tr>

                                  <th scope="col">Rajasthan</th>
                                  <th scope="col">Uttar Pradesh</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Jodhpur</td>
                                  <td>Meerut</td>
                                </tr>
                                <tr>
                                  <td>Pali</td>
                                  <td>Kanpur</td>
                                </tr>
                                <tr>
                                  <td>Jaipur</td>
                                  <td>Sahibabad</td>
                                </tr>
                                <tr>
                                  <td>Balotra</td>
                                  <td>Ghaziabad</td>
                                </tr>
                                <tr>
                                  <td>Bikaner</td>
                                  <td>Lucknow</td>
                                </tr>
                                <tr>
                                  <td>Ajmer</td>
                                  <td>Mathura</td>
                                </tr>
                                <tr>
                                  <td>Bhilwara</td>
                                  <td>Gorakhpur</td>
                                </tr>
                                <tr>
                                  <td>-</td>
                                  <td>Varanasi</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              <SideNavigationIIP />
            </div>
          </div>
        </section >
      </>
    );
  }
}

export default IndiaNetworkData;
