import React from "react";
import './InquiryData.css';

export default class InquiryData extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  /* constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      isFormSubmitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateSubject = this.validateSubject.bind(this);
    this.validateMessage = this.validateMessage.bind(this);

    this.validateField = this.validateField.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleBlur(event) {
    const name = event.target;
    this.validateField(name);
    return;
  }

  handleSubmit(event) {
    event.preventDefault();
    let formFields = [
      "name",
      "email",
      "subject",
      "message"
    ];
    let isValid = true;
    formFields.forEach(field => {
      isValid = this.validateField(field) && isValid;
    });

    if (isValid) this.setState({ isFormSubmitted: true });
    else this.setState({ isFormSubmitted: false });

    return this.state.isFormSubmitted;
  }

  validateField(name) {
    let isValid = false;

    if (name === "name") isValid = this.validateName();
    else if (name === "email") isValid = this.validateEmail();
    else if (name === "subject") isValid = this.validateSubject();
    else if (name === "message") isValid = this.validateMessage();

    return isValid;
  }

  validateName() {
    let nameError = "";
    const value = this.state.name;

    if (value.trim() === "") nameError = "Name is Required";

    this.setState({
      nameError
    });

    return nameError === "";
  }

  validateEmail() {
    let emailError = "";
    const value = this.state.email;

    if (value.trim() === "") emailError = "Email address is Required";
    else if (!emailValidator.test(value)) emailError = "Email is invalid"

    this.setState({
      emailError
    });

    return emailError === "";
  }

  validateSubject() {
    let subjectError = "";
    const value = this.state.subject;

    if (value.trim() === "") subjectError = "Subject is Required";

    this.setState({
      subjectError
    });

    return subjectError === "";
  }

  validateMessage() {
    let messageError = "";
    const value = this.state.subject;

    if (value.trim() === "") subjectError = "Subject is Required";

    this.setState({
      subjectError
    });

    return subjectError === "";
  } */

  render() {
    const { status } = this.state;
    return (


      <form onSubmit={this.submitForm} action="https://formspree.io/f/mknkrpko" method="POST" class="form-contact contact_form" novalidate="novalidate">

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <input class="form-control valid" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" placeholder="Enter your name" required />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <input class="form-control valid" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder="Email" required />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <input class="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'" placeholder="Enter Subject" required />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <textarea class="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" placeholder=" Enter Message" required></textarea>
            </div>
          </div>
        </div>
        <div class="form-group mt-3">
          {status === "SUCCESS" ? <p>Thanks!</p> : <button type="submit" class="button button-contactForm boxed-btn">Send</button>}
          {status === "ERROR" && <p>Ooops! There was an error.</p>}
        </div>
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}