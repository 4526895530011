import React, { Component } from 'react';
import './SaltFreeDyes.css';

class SaltFreeDyes extends React.Component {
  state = {
    showCommercializeProducts: false,
  }

  toggleCommercializedProductsHandler = () => {
    const doShowCommercializeProducts = this.state.showCommercializeProducts;
    this.setState({ showCommercializeProducts: !doShowCommercializeProducts });
  }

  render() {

    let commercializeProducts = null;

    if (this.state.showCommercializeProducts) {
      commercializeProducts = (
        <>
          <h1 class="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Commercialized Products </h1>
          <ul class="list cat-list" >
            <li>
              <h3>Reactive Blue 49</h3>
            </li>
            <li>
              <h3>Reactive Black 8</h3>
            </li>
            <li>
              <h3>Reactive Black 39</h3>
            </li>
            <li>
              <h3>Direct Red 227</h3>
            </li>
            <li>
              <h3>Direct Black 19</h3>
            </li>
            <li>
              <h3>Disperse Blue 291.1</h3>
            </li>
            <li>
              <h3>Reactive Red 218</h3>
            </li>
            <li>
              <h3>Reactive Red 180</h3>
            </li>
            <li>
              <h3>Reactive Red 245</h3>
            </li>
            <li>
              <h3>Reactive Red 24</h3>
            </li>
          </ul>
        </>
      )
    }


    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/SolutionsAndDyes/SaltFreeDyesSlider01.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Salt Free Dyes</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Reactive dyes content start --> */}

          <div class="categories-area section-padding40 gray-bg">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="section-tittle text-justify">
                    <span class="element">What we offer</span>
                    <h2>SALT FREE DYES</h2>
                    <p>
                      A.K.Industries' Acid Dyes are adaptable dyes that may be used in various printing methods, including Warm Exhaust, Hot Exhaust, Cold Patch Batch Dyeing, and Pad Dye Thermofix others. <br /><br />
                      Reactive dyes, the most majestic dyes, lead the cotton dyeing industries still today as they exhibit versatile amenities such as full ranges of shade, convenient fastness properties, and ease of application. Implications of these dyes demand a great deal of electrolyte to subdue the electric charge repulsion between dye and fiber. But the use of a high amount of electrolyte and unfixed dye contributes to environmental pollution, among which salt cannot be exhausted or destroyed. The usage of salt can be eliminated by using cationized reactive dye or modification of cotton.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Reactiv dyes content end --> */}
          {/* <!-- Reactive Dye selection start --> */}
          <div class="container mt-50 mb-50">
            <div class="row">

              <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12" id="selectProcess">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <h1 style={{ color: "#2d2d2d;", textAlign: "center" }}>STATUS</h1>
                  </aside>
                </div>
              </div>

              <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              {/* <h2 style={{ color: "#2d2d2d;", textAlign: "center" }}>Commercialize</h2> */}
                              <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.toggleCommercializedProductsHandler} class="btn">
                                {
                                  !this.state.showCommercializeProducts ?
                                    <>Show Commercialized Products</> :
                                    <>Hide Commercialized products</>
                                }
                              </button>
                              {commercializeProducts}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

            </div>
          </div>

          {/* <!-- Reactive Dye selection end --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}

export default SaltFreeDyes;